import { Component, OnInit } from "@angular/core";
import {
  trigger,
  transition,
  style,
  animate,
  query,
  stagger,
  animateChild,
} from "@angular/animations";
import { OwlOptions } from "ngx-owl-carousel-o";
import sampleData from "../../../assets/data/news.json";
import { Router } from "@angular/router";
import { LangService } from "src/app/_service/lang.service.js";
@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
  animations: [
    trigger("items", [
      transition(":enter", [
        style({ transform: "scale(0.5)", opacity: 0 }), // initial
        animate(
          "1s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({ transform: "scale(1)", opacity: 1 })
        ), // final
      ]),
      transition(":leave", [
        style({ transform: "scale(1)", opacity: 1, height: "*" }),
        animate(
          "1s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({
            transform: "scale(0.5)",
            opacity: 0,
            height: "0px",
            margin: "0px",
          })
        ),
      ]),
    ]),
    trigger("list", [
      transition(":enter", [
        query("@items", stagger(300, animateChild()), { optional: true }),
      ]),
    ]),
  ],
  styleUrls: ["./news.component.css"],
})
export class NewsComponent implements OnInit {
  model: any = {
    month: "",
    year: "",
  };

  modelBtnFilter: any = {
    filter: "",
  };

  modelProjectsFiltter: any[] = [];

  modelProjects: any[] = sampleData;
  page = 1;

  modelHilight: any = {
    title: "",
    desc: "",
    img: "",
  };

  constructor(private router: Router, public langService: LangService) {
    this.langService.currentLang.subscribe((x) => {
      this.setData(x);
    });
  }

  ngOnInit() {
    // this.modelProjectsFiltter = this.paginate(this.modelProjects, 4, this.page);
    const curDate = new Date();
    //this.model.month = ("0" + (curDate.getMonth() + 1)).slice(-2);
    //this.model.year = curDate.getFullYear();
    this.model.month = "";
    this.model.year = "";
    this.filter(this.model.month, this.model.year);
  }

  setData(lang: string) {
    this.modelHilight = {
      title:
        lang === "en"
          ? "Siamese Asset Recognizes Employees Contributing to a Green Society with Eco Point Awards"
          : "ไซมิส แอสเสท มอบรางวัล Eco Point  แก่พนักงานผู้สร้างสรรค์สังคมสีเขียว",
      desc:
        lang === "th"
          ? "บริษัท ไซมิส แอสเสท จำกัด (มหาชน) จัดกิจกรรม \"โครงการแยกขยะ\" ณ สำนักงานใหญ่ เพื่อส่งเสริมการมีส่วนร่วมของพนักงานในการรักษาสิ่งแวดล้อมและสร้างสรรค์สังคมสีเขียวอย่างยั่งยืน โดยกิจกรรมดังกล่าวได้รับความสนใจจากพนักงานเป็นอย่างดี และมีผู้เข้าร่วมโครงการจำนวนมาก  เพื่อเป็นกำลังใจในการทำความดีเพื่อสิ่งแวดล้อม บริษัทฯ ได้มอบรางวัล Eco Point ให้แก่พนักงานที่มีคะแนนสูงสุดจำนวน 20 ท่าน โดยคุณสุนันทา สิ่งสรรเสริญ ประธานเจ้าหน้าที่ฝ่ายปฏิบัติการ บริษัท ไซมิส แอสเสท จำกัด (มหาชน) เป็นผู้มอบรางวัลดังกล่าว "
          : " Siamese Asset Public Company Limited organized the \"Waste Segregation Project\" at its head office to promote employee participation in environmental conservation and create a sustainable green society. The activity garnered significant interest from employees, with a large number participating in the project. To encourage environmentally friendly practices, the company awarded Eco Points to the 20 employees with the highest scores. Mrs. Sunanta Singsansern, Chief Operating Officer of Siamese Asset Public Company Limited, presented the awards.",
      img:
        lang === "en"
          ? "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2025-03/waste-separation-activites-ecolife.jpg"
          : "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2025-03/waste-separation-activites-ecolife.jpg",
    };
    // this.modelHilight = {
    //   title:
    //     lang === "en"
    //       ? "Siamese Asset and RITTA Join Forces with the Faculty of Engineering, Chulalongkorn University, Launching the 'Zero Construction Wastes and Upcycling Products' Project,Aiming to Reduce Construction Waste and Create Eco-Friendly Products."
    //       : "ไซมิส แอสเสท และฤทธา จับมือคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย เปิดตัวโครงการ \"Zero Construction Wastes and Upcycling Products\" มุ่งลดขยะก่อสร้าง สร้างสรรค์ผลิตภัณฑ์รักษ์โลก",
    //   desc:
    //     lang === "th"
    //       ? "บริษัท ไซมิส แอสเสท จำกัด (มหาชน) และบริษัท ฤทธา จำกัด ร่วมกับคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย ประกาศความร่วมมือในโครงการ \"Zero Construction Wastes and Upcycling Products\" มุ่งมั่นที่จะลดปริมาณขยะจากการก่อสร้าง และนำวัสดุเหลือใช้มาสร้างสรรค์ผลิตภัณฑ์ที่มีมูลค่าเพิ่มและเป็นมิตรต่อสิ่งแวดล้อม"
    //       : "Siamese Asset Public Company Limited and RITTA Company Limited, in collaboration with the Faculty of Engineering, Chulalongkorn University, have announced a partnership in the \"Zero Construction Wastes and Upcycling Products\" project, aiming to reduce construction waste and transform residual materials into value-added, environmentally friendly products.",
    //   img:
    //     lang === "en"
    //       ? "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2025-03/RITTA_Event-03.webp"
    //       : "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2025-03/RITTA_Event-03.webp",
    // };
    // this.modelHilight = {
    //   title:
    //     lang === "en"
    //       ? "Hong Kong IRES Delegation Visits LANDMARK AT MRTA STATION, Reinforcing International Real Estate Development Potential"
    //       : "คณะผู้แทน IRES จากฮ่องกง เยี่ยมชมโครงการ LANDMARK AT MRTA STATION ตอกย้ำศักยภาพการพัฒนาอสังหาริมทรัพย์ระดับนานาชาติ",
    //   desc:
    //     lang === "th"
    //       ? "คณะผู้แทนจาก The Hong Kong Trade Development Council (HKTDC) เดินทางมาเยี่ยมชมโครงการ LANDMARK AT MRTA STATION ซึ่งเป็นโครงการพัฒนาอสังหาริมทรัพย์แบบผสมผสาน (Mixed-Use) ที่โดดเด่นของบริษัท ไซมิส แอสเสท จำกัด (มหาชน) โดยมีคุณสุนันทา สิ่งสรรเสริญ ประธานเจ้าหน้าที่ฝ่ายปฏิบัติการ ให้การต้อนรับและนำคณะเยี่ยมชมโครงการ"
    //       : "A delegation from The Hong Kong Trade Development Council (HKTDC) visited the LANDMARK AT MRTA STATION project, a prominent mixed-use real estate development by Siamese Asset Public Company Limited Mrs. Sunanta Singsansern ,Chief Operating Officer, welcomed the delegation and led the project tour.",
    //   img:
    //     lang === "en"
    //       ? "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2025-03/218.webp"
    //       : "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2025-03/218.webp",
    // };
  }

  filter(month, year) {
    this.page = 1;
    this.modelProjectsFiltter = [];
    // tslint:disable-next-line: only-arrow-functions
    this.modelProjectsFiltter = this.modelProjects
      .filter(function (el: any) {
        return (
          (el.month.toUpperCase().indexOf(month) === 0 || month === "") &&
          (el.year.toUpperCase().indexOf(year) === 0 || year === "")
        );
      })
      .sort((a, b) =>
        a.newsDate < b.newsDate ? 1 : b.newsDate < a.newsDate ? -1 : 0
      );

    this.modelProjectsFiltter = this.paginate(
      this.modelProjectsFiltter,
      4,
      this.page
    );
  }

  paginate(array, pageSize, pageNumber) {
    --pageNumber; // because pages logically start with 1, but technically with 0
    return array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
  }

  viewMore(month, year) {
    this.page = this.page + 1;

    const dataview = this.modelProjects
      .filter(function (el: any) {
        return (
          (el.month.toUpperCase().indexOf(month) === 0 || month === "") &&
          (el.year.toUpperCase().indexOf(year) === 0 || year === "")
        );
      })
      .sort((a, b) =>
        a.newsDate < b.newsDate ? 1 : b.newsDate < a.newsDate ? -1 : 0
      );

    const resp = this.paginate(dataview, 4, this.page);
    resp.forEach((item) => {
      this.modelProjectsFiltter.push(item);
    });
  }

  goto(page) {
    this.router.navigate(["/news-detail"], { queryParams: { id: page } });
  }
}

export interface Food {
  value: string;
  viewValue: string;
}

// ,
// {
//   "imageUrl": "./assets/images/news/2024/02/19/news-01.jpg",
//   "credit": "บริษัท เอ็ม ที มัลติมีเดีย จำกัด (ในนาม บมจ. ไซมิส แอทเสท)",
//   "year": "2024",
//   "month": "02",
//   "date": "19",
//   "headerTh": "EXCLUSIVE OPEN HOUSE LANDMARK AT MRTA STATION",
//   "headerEn": "EXCLUSIVE OPEN HOUSE LANDMARK AT MRTA STATION",
//   "Id": "178",
//   "newsDate": "2024-02-19",
//   "pdfFile": ""
// }
