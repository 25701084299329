import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangService } from 'src/app/_service/lang.service';

@Component({
  selector: 'app-readytomove',
  templateUrl: './readytomove.component.html',
  styleUrls: ['./readytomove.component.css']
})
export class ReadytomoveComponent implements OnInit {
  modelData: any[] = [
    {
      siteUrl: 'https://blossomsathorn.com/',
      imageMain: 'assets/images/home/Section3/Preview-02.jpg',
      imageHover: 'assets/images/logos_ci/Siamese asset-logo-project-09.jpg',
      title: (this.langService.currLang() === 'en') ? 'Blossom Condo At' : 'บลอสซั่ม คอนโด แอท',
      line1: (this.langService.currLang() === 'en') ? 'Sathorn-Charoenrat' : 'สาทร-เจริญราษฎร์',
      line2: (this.langService.currLang() === 'en') ? '900 Meters from BTS Surasak' : '900 เมตร จาก BTS สุรศักดิ์',
      line3: (this.langService.currLang() === 'en') ? 'Register for special privileges' : 'ลงทะเบียนเพื่อรับสิทธิพิเศษ',
      line4: (this.langService.currLang() === 'en') ? 'Sathorn-Charoenrat' : 'สาทร-เจริญราษฎร์',
      isOver: false
    },
    {
      siteUrl: 'http://siameseexqueens.com/',
      imageMain: 'assets/images/home/Section2/Preview-06.jpg',
      imageHover: 'https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/logo/hover/hover-logo-queen.jpg',
      title: (this.langService.currLang() === 'en') ? 'Wyndham Bangkok Queen Convention' : 'วินแดม แบงค์กอก ควีนส์ คอนเวนชัน',
      line1: (this.langService.currLang() === 'en') ? 'Centre' : 'เซ็นเตอร์',
      // tslint:disable-next-line: max-line-length
      line2: (this.langService.currLang() === 'en') ? '50 meters from MRT Queen Sirikit Center' : '50 เมตรจาก MRT ศูนย์การประชุมแห่งชาติสิริกิติ์',
      line3: (this.langService.currLang() === 'en') ? 'Register for special privileges' : 'ลงทะเบียนเพื่อรับสิทธิพิเศษ',
      line4: (this.langService.currLang() === 'en') ? 'Asok-Rama 4' : 'อโศก-พระราม 4',
      isOver: false
    },
    {
      siteUrl: 'http://web.siameseasset.co.th/kin-ramintra/',
      imageMain: 'assets/images/home/Section2/kinscg.jpg',
      imageHover: 'assets/images/logos_ci/kin_logo.jpg',
      title: (this.langService.currLang() === 'en') ? 'Siamese Kin Ramintra' : 'ไซมิส คิน รามอินทรา เฟส 1 (SCG HEIM)',
      line1: (this.langService.currLang() === 'en') ? 'Phase 1 (SCG HEIM)' : '',
      line2: (this.langService.currLang() === 'en') ? '1 KM.from Wongwaen-Ramintra' : '1 กม.สถานีวงแหวนรามอินทรา',
      line3: (this.langService.currLang() === 'en') ? '1 KM.from Fasion Island' : '1 กม.แฟชั่นไอส์แลนด์',
      line4: (this.langService.currLang() === 'en') ? 'Ratchada-Ramintra Road' : 'รัชดา-รามอินทรา',
      isOver: false
    }
  ];

  // tslint:disable-next-line: max-line-length
  modelKin = (this.langService.currLang() === 'en') ? 'assets/images/home/Section3/kin01.jpg' : 'assets/images/home/Section3/kin01.jpg';

  constructor(private router: Router, public langService: LangService) { }

  ngOnInit() { }

  goto() {
    //const id = '4';
    //this.router.navigate(['/projects', id]);
    this.router.navigate(['/projects']);
  }
}
