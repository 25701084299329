import { Component, Input, OnInit } from '@angular/core';

import actData from "../../../assets/data/activites.json";
import newData from "../../../assets/data/news.json";
import { LangService } from 'src/app/_service/lang.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-news-suggest',
  templateUrl: './news-suggest.component.html',
  styleUrls: ['./news-suggest.component.css']
})
export class NewsSuggestComponent implements OnInit {

  constructor(private langService: LangService, private router: Router) {

  }

  currentLang: string = this.langService.currLang();
  modelProjects: any[] = [...actData, ...newData];
  @Input() esgType: string;

  newsList = [

    // เพิ่มข่าวเพิ่มเติมที่นี่
  ];

  currentIndex = 0;

  ngOnInit() {
    
    this.langService.currentLang.subscribe(x => {

      this.currentLang = x;
    });

    if (this.esgType) {
      const filteredProjects = this.modelProjects.filter(x => x.esg === this.esgType).map(x => {
        return {
          id: x.Id, image: x.imageUrl, titleTh: x.headerTh, titleEn: x.headerEn, description: "",newsDate:x.newsDate
        }
      });
      this.newsList = [...this.newsList, ...filteredProjects];
    } else {
      this.newsList = this.modelProjects.map(x => {
        return {
          id: x.Id, image: x.imageUrl, titleTh: x.headerTh, titleEn: x.headerEn, description: "",newsDate:x.newsDate
        }
      });
    }

    // สุ่มเรียงลำดับข่าวใน newsList
    this.newsList = this.newsList.sort(() => Math.random() - 0.5);

    
  }

  isVisible(index: number): boolean {
    return index >= this.currentIndex && index < this.currentIndex + 3;
  }

  prev(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  next(): void {
    if (this.currentIndex < this.newsList.length - 3) {
      this.currentIndex++;
    }
  }

  goto(page) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(["/news-detail"], { queryParams: { id: page } });
    });
  }

}
