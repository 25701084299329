import { Component, OnInit } from "@angular/core";
import {
  trigger,
  transition,
  query,
  stagger,
  animate,
  style,
  animateChild,
} from "@angular/animations";
import { OrderPipe } from "ngx-order-pipe";
import sampleData from "../../../assets/data/projects.json";
import { LangService } from 'src/app/_service/lang.service';
@Component({
  selector: "app-project-all",
  templateUrl: "./project-all.component.html",
  animations: [
    trigger("items", [
      transition(":enter", [
        style({ transform: "scale(0.5)", opacity: 0 }), // initial
        animate(
          "1s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({ transform: "scale(1)", opacity: 1 })
        ), // final
      ]),
      transition(":leave", [
        style({ transform: "scale(1)", opacity: 1, height: "*" }),
        animate(
          "1s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({
            transform: "scale(0.5)",
            opacity: 0,
            height: "0px",
            margin: "0px",
          })
        ),
      ]),
    ]),
    trigger("list", [
      transition(":enter", [query("@items", stagger(300, animateChild()))]),
    ]),
  ],
  styleUrls: ["./project-all.component.css"],
})
export class ProjectAllComponent implements OnInit {
  
  modelBtnFilter: any = {
    filter: "",
    type: "",
  };

  modelProjectsFiltter: any[] = [];

  modelProjects: any[] = sampleData;

  constructor(public orderPipe: OrderPipe,public langService: LangService) {
    this.modelProjectsFiltter = orderPipe.transform(
      this.modelProjects,
      "order"
    );
  }

  ngOnInit() {
    //this.modelBtnFilter.filter = 'Z';
    this.onfilter("B", "C");
    // this.modelProjectsFiltter = this.modelProjects;
  }

  onfilter(filter, type) {
    this.modelBtnFilter.filter = filter;
    this.modelBtnFilter.type = type;
    this.modelProjectsFiltter = [];
    
    if (filter === "") {
      this.modelProjectsFiltter = this.modelProjects.filter((s) => {
        return s.projectType === type; 
      });
    } else if (filter !== "A") {
      // tslint:disable-next-line: only-arrow-functions
      this.modelProjectsFiltter = this.modelProjects.filter(function (el: any) {
        return el.type === filter
      });
    }

    this.modelProjectsFiltter = this.orderPipe.transform(
      this.modelProjectsFiltter,
      "order"
    );
  }
}
