import { Component, OnInit } from "@angular/core";
import {
  trigger,
  transition,
  style,
  animate,
  query,
  stagger,
  animateChild,
} from "@angular/animations";
import { OwlOptions } from "ngx-owl-carousel-o";
import sampleData from "../../../assets/data/news.json";
import { Router } from "@angular/router";
import { LangService } from "src/app/_service/lang.service.js";
@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
  animations: [
    trigger("items", [
      transition(":enter", [
        style({ transform: "scale(0.5)", opacity: 0 }), // initial
        animate(
          "1s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({ transform: "scale(1)", opacity: 1 })
        ), // final
      ]),
      transition(":leave", [
        style({ transform: "scale(1)", opacity: 1, height: "*" }),
        animate(
          "1s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({
            transform: "scale(0.5)",
            opacity: 0,
            height: "0px",
            margin: "0px",
          })
        ),
      ]),
    ]),
    trigger("list", [
      transition(":enter", [
        query("@items", stagger(300, animateChild()), { optional: true }),
      ]),
    ]),
  ],
  styleUrls: ["./news.component.css"],
})
export class NewsComponent implements OnInit {
  model: any = {
    month: "",
    year: "",
  };

  modelBtnFilter: any = {
    filter: "",
  };

  modelProjectsFiltter: any[] = [];

  modelProjects: any[] = sampleData;
  page = 1;

  modelHilight: any = {
    title:
      this.langService.currLang() === "en"
        ? "Siamese Asset PCL signed a memorandum of understanding (MOU) for collaboration on the \"Zero Construction Wastes and Upcycling Products\" project in partnership with Ritta Co., Ltd. and the Faculty of Engineering, Chulalongkorn University"
        : "บริษัท ไซมิส แอสเสท จำกัด (มหาชน) ลงนามบันทึกข้อตกลงความร่วมมือ โครงการ Zero Construction Wastes and Upcycling Products ร่วมกับ บริษัท ฤทธา จำกัด และ คณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
    desc:
      this.langService.currLang() === "en"
        ? "On December 11, 2024, Siamese Asset PCL, represented by Mrs. Sunanta Singsansern, Director and Chief Operating Officer, and Mr. Monthien Yingdamnoon, Director and Chief Business Development Officer, joined hands with Ritta Co., Ltd., represented by Mr. Panitan Tepnikorn, Director and Co-Chief Executive Officer, and Mr. Phetshrat Phetdee, Executive Director and General Manager."
        : "เมื่อวันที่ 11 ธันวาคม 2567 บริษัท ไซมิส แอสเสท จำกัด (มหาชน) โดยนางสุนันทา สิ่งสรรเสริญ กรรมการบริษัทและประธานเจ้าหน้าที่ฝ่ายปฏิบัติการ และนายมณเฑียร ยิ่งดำนุ่น กรรมการบริษัทและประธานเจ้าหน้าที่ฝ่ายพัฒนาธุรกิจ ร่วมกับ บริษัท ฤทธา จำกัด โดย คุณปณิธาน เทพนิกร กรรมการบริษัทและประธานเจ้าหน้าที่บริหารร่วม และคุณเพชรรัตน์ เพชรดี กรรมการบริหารและผู้จัดการทั่วไป ",
    img:
      this.langService.currLang() === "en"
        ? "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2024-12/20241212-01.jpg"
        : "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2024-12/20241212-01.jpg",
  };

  constructor(private router: Router, public langService: LangService) {}

  ngOnInit() {
    // this.modelProjectsFiltter = this.paginate(this.modelProjects, 4, this.page);
    const curDate = new Date();
    //this.model.month = ("0" + (curDate.getMonth() + 1)).slice(-2);
    //this.model.year = curDate.getFullYear();
    this.model.month = "";
    this.model.year = "";
    this.filter(this.model.month, this.model.year);
  }

  filter(month, year) {
    this.page = 1;
    this.modelProjectsFiltter = [];
    // tslint:disable-next-line: only-arrow-functions
    this.modelProjectsFiltter = this.modelProjects
      .filter(function (el: any) {
        return (
          (el.month.toUpperCase().indexOf(month) === 0 || month === "") &&
          (el.year.toUpperCase().indexOf(year) === 0 || year === "")
        );
      })
      .sort((a, b) =>
        a.newsDate < b.newsDate ? 1 : b.newsDate < a.newsDate ? -1 : 0
      );

    this.modelProjectsFiltter = this.paginate(
      this.modelProjectsFiltter,
      4,
      this.page
    );
  }

  paginate(array, pageSize, pageNumber) {
    --pageNumber; // because pages logically start with 1, but technically with 0
    return array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
  }

  viewMore(month, year) {
    this.page = this.page + 1;

    const dataview = this.modelProjects
      .filter(function (el: any) {
        return (
          (el.month.toUpperCase().indexOf(month) === 0 || month === "") &&
          (el.year.toUpperCase().indexOf(year) === 0 || year === "")
        );
      })
      .sort((a, b) =>
        a.newsDate < b.newsDate ? 1 : b.newsDate < a.newsDate ? -1 : 0
      );

    const resp = this.paginate(dataview, 4, this.page);
    resp.forEach((item) => {
      this.modelProjectsFiltter.push(item);
    });
  }

  goto(page) {
    this.router.navigate(["/news-detail"], { queryParams: { id: page } });
  }
}

export interface Food {
  value: string;
  viewValue: string;
}

// ,
// {
//   "imageUrl": "./assets/images/news/2024/02/19/news-01.jpg",
//   "credit": "บริษัท เอ็ม ที มัลติมีเดีย จำกัด (ในนาม บมจ. ไซมิส แอทเสท)",
//   "year": "2024",
//   "month": "02",
//   "date": "19",
//   "headerTh": "EXCLUSIVE OPEN HOUSE LANDMARK AT MRTA STATION",
//   "headerEn": "EXCLUSIVE OPEN HOUSE LANDMARK AT MRTA STATION",
//   "Id": "178",
//   "newsDate": "2024-02-19",
//   "pdfFile": ""
// }
