import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { LangService } from 'src/app/_service/lang.service';

@Component({
  selector: 'app-about-vision',
  templateUrl: './about-vision.component.html',
  styleUrls: ['./about-vision.component.css']
})
export class AboutVisionComponent implements OnInit {

  textVision = ((this.langService.currLang() === 'en')) ? 'Siamese Asset is committed to being the Sustainable Living Value’s Creator for full-service real estate products and innovations that provide value to stakeholders under the “Asset of Life” concept' : 'ไซมิสฯ มุ่งมั่นที่จะเป็นผู้สร้างคุณค่าของผลิตภัณฑ์ บริการ และนวัตกรรมด้านอสังหาริมทรัพย์แบบครบวงจรอย่างยั่งยืน ที่ตอบสนองความคุ้มค่าให้กับทุกคน ภายใต้แนวคิด ‘สร้างกำไรให้ทุกการใช้ชีวิต’ (Asset of Life)';
  textMission = ((this.langService.currLang() === 'en')) ? 'We are thinking and developing real estate and services on a global standard, using knowledge, professional experience, and innovation, conducted with integrity for sustainable growth in ethics, society, and the environment for the benefit of all stakeholders.' : 'เราคิดและพัฒนา อสังหาริมทรัพย์และบริการในระดับสากลโดยใช้องค์ความรู้ ประสบการณ์มืออาชีพ และนวัตกรรม ภายใต้การดำเนินธุรกิจอย่างมีจรรยาบรรณ เพื่อการเติบโตอย่างยั่งยืน ทั้งด้านธรรมาภิบาล สังคมและสิ่งแวดล้อม ให้กับผู้มีส่วนได้เสียทุกภาคส่วน';

  image_vision = ((this.langService.currLang() === 'en')) ? 'assets/images/about/Vision_Mission/2025/head_en_0.jpg' : 'assets/images/about/Vision_Mission/2025/head_th_0.jpg';
  constructor(public langService: LangService) { }

  ngOnInit() {
    this.langService.currentLang.subscribe((lang) => {
      this.image_vision = ((lang === 'en')) ? 'assets/images/about/Vision_Mission/2025/head_en_0.jpg' : 'assets/images/about/Vision_Mission/2025/head_th_0.jpg';
    });
  }

}
