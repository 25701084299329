import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LangService {

  private globalLang = new BehaviorSubject<string>('th');

  currentLang = this.globalLang.asObservable();

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService
  ) {}

  changeLang(lang: string) {
    lang = lang ? lang : "th";
    this.translate.setDefaultLang(lang.toLocaleLowerCase());
    this.cookieService.set("lang", lang);
    localStorage.setItem("lang", lang);
    document.cookie = "lang=" + lang;
    this.globalLang.next(lang);
  }

  currLang() {
    const lang = localStorage.getItem("lang");
    return lang;
  }
}
